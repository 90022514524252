<div class="sednDatil" *ngIf="Item">
  <div class="title">
    <span>
      {{editTYpe == 'create'? 'Create SEDN': Item.file_id+ ' ' + Item.state}}
    </span>
    <span *ngIf="Item.replied_type == 'Group'">
      {{Item.replied_by.firstname + ' ' + Item.replied_by.lastname}}
    </span>
    <span class="executor" *ngIf="this.replied_right && Item.replied_type== 'Group'" (click)="handleExecutor()" [ngClass]="{'canNotClick': !this.replied_right}">
      {{this.Item.executor_id?  'Release': 'I Handle'}}
    </span>
  </div>
  <div class="detailInput">
    <div>
      <span class="type">Deficiencies Type<span class="required">*</span></span>
      <select [disabled]="isWriteIn?isWriteIn:Item.prepared_right? false:'disabled'" class="value" [(ngModel)]="Item.deficiency_id">
        <option></option>
        <option *ngFor="let item of deFicienciesList" [value]="item.id">
          {{item.deficiency_name}}
        </option>
      </select>
    </div>
    <div>
      <span class="type">Location <span class="required">*</span></span>
      <input class="value" [disabled]="isWriteIn" *ngIf="Item.prepared_right" [(ngModel)]="Item.location"/>
      <span class="value" *ngIf="!Item.prepared_right">{{Item.location}}</span>
    </div>
    <div>
    <span class="type">Site Activity <span class="required">*</span></span>
    <input *ngIf="Item.prepared_right" [disabled]="isWriteIn" class="value" [(ngModel)]="Item.site_activity"/>
    <span *ngIf="!Item.prepared_right" class="value">{{Item.site_activity}}</span>
    </div>
  </div>
  <div class="photoGroup">
    <SEDNImgGroup *ngFor="let item of ImgGroup let i = index" [sednId]="Item.id" [GroupId]="item.id" [state]="Item.state"  [isWriteIn]="isWriteIn" [replied_right]="Item.replied_right" [prepared_right]="Item.prepared_right" [groupIndex]="i" (groupEventEmit)="photoGroupEvent($event, i)" (lengthNot0Emit)="imgBoolean = true"></SEDNImgGroup>
    <!-- <div class="addNewphotoGroup" (click)="addNewphotoGroup()" *ngIf="Item.prepared_right && Item.state == 'New'">
      Add New photoGroup
    </div> -->
  </div>
  <div class="actionRequire" >
    <div>Action Required</div>
    <textarea  [(ngModel)]="Item.action_required" *ngIf="Item.prepared_right" > </textarea>
    <div *ngIf="!Item.prepared_right">
      {{Item.action_required}}
    </div>
  </div>
  <div>
    <span>{{Item.prepared_by.firstname + ' ' + Item.prepared_by.lastname + ' ' + '(' + Item.prepared_by.position + ')' }}  Sign</span>
    <div class='attributes-key-value--value-container'
    [ngClass]="{canNotSign: !Item.prepared_right }"
    style="display: flex; flex-direction: column;">
      <!-- handler 下拉框下面的文字 -->
      <span style="margin: 10px 0;" ></span>
      <div class="attributes-key-value--value-container-content"
        *ngIf="!actionSign && !signloding"
        (click)="displaysign('action')"
       >
        <div class="attributes-key-value--value-container-content-icon"
        >
          <span class="icon-add" style="color: #fff;"></span>
        </div>
      </div>
      <div class="attributes-key-value__loading" *ngIf="signloding">
        <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
        </div>
      </div>
      <div class="deleteSign" (click)="deleteActionSign()" *ngIf="actionSign&&Item.prepared_right">X</div>
      <img class="signImg"  [src]='actionSign' *ngIf="actionSign"/>


    </div>
  </div>
  <div class="Reply"  *ngIf="Item.state !== 'New' ">
    <div class="remark">

      <div>Contractor's rectification</div>
      <textarea [(ngModel)]="Item.remark" *ngIf="Item.state == 'Sent'" [disabled]="(Item.state!=='Sent' || !Item.replied_right)?'disabled': false"></textarea>
      <div *ngIf="Item.state !== 'Sent'">
        {{Item.remark}}
      </div>
    </div>
    <div class='attributes-key-value--value-container'     [ngClass]="{'canNotSign': !Item.replied_right}"  style="display: flex; flex-direction: column;">
      <!-- handler 下拉框下面的文字 -->
      <span *ngIf="Item.replied_type == 'User' || Item.executor_id">{{!Item.executor_id?(Item.replied_by.firstname + ' ' + Item.replied_by.lastname + ' ' + '(' + Item.replied_by.position + ')'): (Item.executor.firstname + ' ' + Item.executor.lastname + ' ' + '(' + Item.executor.position + ')')}} Sign</span>
      <span style="margin: 10px 0;" ></span>
      <div class="attributes-key-value--value-container-content"
        *ngIf="!replySign && !reSignLoad"
        (click)="displaysign('reply')"
       >
        <div class="attributes-key-value--value-container-content-icon"
        >
          <span class="icon-add" style="color: #fff;"></span>
        </div>
      </div>
      <div class="attributes-key-value__loading" *ngIf="reSignLoad">
        <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
        </div>
      </div>
      <div class="deleteSign" (click)="deleteReplySign()" *ngIf="replySign&&Item.replied_right">X</div>
      <img class="signImg"  [src]='replySign' *ngIf="replySign"/>


    </div>
  </div>
  <div class="Button" *ngIf="Item.state !== 'Closed'">
    <div class="Save" *ngIf="Item.state !== 'Completed'" (click)="saveSEDN()" [ngClass]="{'canNotClick': !Item.prepared_right && !Item.replied_right}">Save</div>
    <div class="assign" (click)="Assign()"*ngIf="Item.prepared_right" [ngClass]="{disable: !imgBoolean|| !Item.deficiency_id||!Item.location || !Item.site_activity}" >Assign</div>
    <div class="complete" *ngIf="Item.replied_right && replySign" (click)="CompleteSave()">
      Complete
    </div>
    <div class="closed" *ngIf="Item.prepared_right && Item.state == 'Completed'" (click)="closeSedn()">
      Close
    </div>
  </div>
  <SEDNSign *ngIf="dipslesignature" (closeView)='closeView($event)'></SEDNSign>
  <div class="checkAssWage" *ngIf="ifCheckAssign" (click)="ifCheckAssign = false"></div>
  <div class="assignCheck" *ngIf="ifCheckAssign">
    <div class="header">
      <div>Select an Individual or a group</div>
      <div class="close layui-layer-ico" (click)="ifCheckAssign = false"></div>
    </div>
    <div class="contentDialog">
    <div class="selectAssignType">
      <div>

        <input class="radio_type" type="radio" name="type" id="radio1" (change)="changeAssignTo('Indlvidual')" [checked]="assignType == 'Indlvidual'?'checked':''"/>
        <label for="radio1">Individual</label>
      </div>
      <div>

        <input class="radio_type" type="radio" (change)="changeAssignTo('group')" name="type" id="radio2" [checked]="assignType == 'group'?'checked':''" />
        <label for="radio2">Group</label>
      </div>
    </div>

    <div class="table">
    <table>
      <thead>
        <tr>
          <th>Assign to</th>
          <th class="name"></th>
          <th>cc:</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of constructorSelect">
          <td>
            <input class="checkbox_type" type="radio" name="assignId" [value]="item.id"  [(ngModel)]="constructorUserId"  />
          </td>
          <td class="name">
            <div>
              {{item.firstname + item.lastname}}
            </div>
            <div *ngIf="assignType == 'Indlvidual'">
              {{item.position?item.position: '——'}}
            </div>
          </td>
          <td><input class="checkbox_type" type="checkbox" [value]="item.id" name="type" [(ngModel)]="item.checked"   /> </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="assignButton">

    <div class="cancel" (click)="ifCheckAssign = false" >
      Cancel
    </div>
    <div class="confirm" (click)="AssignSave()" [ngClass]="{'canNotClick': !constructorUserId}">
      Confirm
    </div>
  </div>
  </div>
</div>
</div>

<div class="attributes-key-value__loading uploadLoading" *ngIf="uploadLoading">
  <div class="loading-indicator -compact">
  <div class="block-1"></div>
  <div class="block-2"></div>
  <div class="block-3"></div>
  <div class="block-4"></div>
  <div class="block-5"></div>
  </div>
</div>
<div class="showImgWage" *ngIf="showImg" (click)="showImg = null"></div>
<ImgShow  class="showImg" *ngIf="showImg" [showImg]="showImg"></ImgShow>

