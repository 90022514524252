<div class="sharePdf-mask">
  <div class="box">
      <div class="main">
          <div class="header">
              <div>{{ i18n.t("js.single-sharePdf.share-pdf") }}</div>
              <div (click)= "Close()" class="close layui-layer-ico"></div>
          </div>
          <div class="content">
            <div class="pdf">
              <input type="text" id="copyUrl" [value]='pdfUrl' class="pdf-input" readonly="readonly">
              <button (click)="CopyPdfUrl()" class="copy-pdf">{{ i18n.t("js.single-sharePdf.copy") }}</button>
            </div>
            <div class="email">
              <p>{{ i18n.t("js.single-sharePdf.send-email") }}</p>
              <div class="input-content">
                <div class="substance" *ngIf="mailbox">
                  <ul class="email-account">
                    <li *ngFor="let val of eList" class="account">
                      <svg t="1596678022901" class="icon1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="575" width="30" height="30"><path d="M512 170.666667a341.333333 341.333333 0 1 1 0 682.666666 341.333333 341.333333 0 0 1 0-682.666666z m42.666667 362.666666h-85.333334a128 128 0 0 0-128 128h341.333334l-0.213334-7.509333A128 128 0 0 0 554.666667 533.333333z m-42.666667-213.333333a85.333333 85.333333 0 1 0 0 170.666667 85.333333 85.333333 0 0 0 0-170.666667z" fill="#444444" p-id="576"></path></svg>
                      <span class="number">{{val}}</span>
                      <a href="" (click)= "Delemail(val)">
                        <svg t="1596682861635" class="icon2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="825" width="14" height="14"><path d="M628.288 512l319.68 319.68c16.064 16.064 16.064 42.048 0 58.112l-58.112 58.112c-16.064 16.064-42.048 16.064-58.112 0L512 628.288l-319.68 319.68c-16.064 16.064-42.048 16.064-58.112 0l-58.112-58.112c-16.064-16.064-16.064-42.112 0-58.112L395.712 512 76.032 192.32c-16.064-16.064-16.064-42.048 0-58.112l58.112-58.112c16.064-16.064 42.112-16.064 58.112 0L512 395.712l319.68-319.68c16.064-16.064 42.112-16.064 58.112 0l58.112 58.112c16.064 16.064 16.064 42.112 0 58.112L628.288 512z" p-id="826" fill="#cdcdcd"></path></svg>
                      </a>
                    </li>
                  </ul>
                </div>
                <input type="email" class="substance-content" placeholder="Add user email" [(ngModel)]="eAlone" (change)="onChange()">
              </div>
              <button (click)="Transmit()" class="send-email">{{ i18n.t("js.single-sharePdf.send") }}</button>
            </div>
          </div>
      </div>
  </div>
</div>
<div class="loading-indicator -compact" *ngIf="loading">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
 </div>
