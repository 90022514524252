<div class="titleclss" *ngIf="summaryData">
  <div class="check-title">
    <div class="toolbar-container">
      <div class="toolbar">
        <button>{{summaryData.state=='draft'? 'Draft' : summaryData.state}}</button>
        <div class="title-container" style="display: flex;">
          <h2  class="titleName">{{summaryData.daily_date}}</h2>
        </div>
      </div>
      <div class="rightButton">
        <button (click)='submitClick()' *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">Save</button>
        <button style="margin-left: 30px;background-color: #e15353;" (click)='closeClick()'>Cancel</button>
      </div>
    </div>
  </div>

  <div class="classification">
    <div [ngClass]="{divClick: divClick=='summary'}" (click)="classificationClick('summary')">Summary</div>
    <div [ngClass]="{divClick: divClick=='activity'}" (click)="classificationClick('activity')">Activity</div>
    <div [ngClass]="{divClick: divClick=='labour'}" (click)="classificationClick('labour')">Labour / People</div>
    <div [ngClass]="{divClick: divClick=='plant'}" (click)="classificationClick('plant')">Plant / Equipment</div>
  </div>

  <!-- addActivity弹窗 -->
  <SiteDiary-Details-addActivity [summaryData]="summaryData" [updataActivty]="updataActivty" (close)="addActivtyClick()" *ngIf="addActivty"></SiteDiary-Details-addActivity>

  <!-- Summary -->
  <SiteDiary-Details-Summary *ngIf="divClick=='summary'" [summaryData]="summaryData"></SiteDiary-Details-Summary>

  <!-- Activity   Labour / People    Plant / Equipment -->
  <SiteDiary-Details-table *ngIf=" divClick=='labour' || divClick=='plant'" [addActivty]="addActivty" [divClick]='divClick' [summaryData]="summaryData" (tableEdit)='tableEdit($event)'></SiteDiary-Details-table>
  <SiteDiaty-Activity-New *ngIf="divClick=='activity' " [divClick]='divClick'
  [summaryData]="summaryData" (tableEdit)='tableEdit($event)'></SiteDiaty-Activity-New>
</div>
<div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
  <div class="loading-indicator -compact">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
</div>