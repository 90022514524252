<div class="generic-table--sort-header-outer" [ngSwitch]="columnType" style="height: 90px;">

  <span class="generic-table--sort-header" *ngSwitchCase="'hierarchy'" style=" word-break: break-all;" [ngClass]="{
    'NewColumn': isNewColumn
  }" [ngStyle]="{
      'min-width':isNewColumn? '200px': '40px',
      'white-break': isNewColumn? 'break-all': ''
  }"
  [class]="columnId"
  >
    <span
        class="hierarchy-header--icon"
        *ngIf="displayHierarchyIcon"
        (click)="toggleHierarchy($event)"
        [attr.title]="text.toggleHierarchy"
        tabindex="-1"
        aria-hidden="true">
      <op-icon icon-classes="icon {{ hierarchyIcon }}"></op-icon>
    </span>

    <!--suppress XmlDuplicatedId -->
    <a href="#"
       *ngIf="sortable"
       class="a_nowrap"
       [ngClass]="[directionClass && 'sort', directionClass]"
       lang-attribute
       lang="{{locale}}"
       id="{{ headerColumn.id }}">{{headerColumn.name}}</a>
    <a  class="a_nowrap" *ngIf="!sortable">{{headerColumn.name}}</a>

    <op-icon icon-classes="dropdown-indicator icon-small icon-pulldown"
             icon-title="{{ text.openMenu }}"
             *ngIf="displayDropdownIcon&&headerColumn.flag"></op-icon>
  </span>

  <span class="generic-table--sort-header" *ngSwitchCase="'relation'" [ngClass]="{
    'NewColumn': isNewColumn
  }"
  [class]="columnId">
    <a [attr.title]="headerColumn.name" class="a_nowrap">
      <op-icon icon-classes="icon-context icon-relations relations-header--icon"></op-icon>
      {{columnName}}
    </a>
    <op-icon icon-classes="dropdown-indicator icon-small icon-pulldown"
             icon-title="{{ text.openMenu }}"
             *ngIf="displayDropdownIcon&&headerColumn.flag"></op-icon>
  </span>

  <span class="draggable-table--sort-header"
        [attr.title]="text.sortColumn"
        *ngSwitchCase="'sort'" [ngClass]="{
          'NewColumn': isNewColumn
        }">
  </span>

  <span class="generic-table--sort-header" [ngClass]="{
    'NewColumn': isNewColumn
  }"
  [class]="columnId"
   *ngSwitchDefault>

    <!--suppress XmlDuplicatedId -->
    <a href="#"
       *ngIf="sortable"
       class="a_nowrap"
       [ngClass]="[directionClass && 'sort', directionClass]"
       lang-attribute
       lang="{{locale}}"
       id="{{ headerColumn.id }}">{{headerColumn.name}}</a>

    <a class="a_nowrap" *ngIf="!sortable">{{headerColumn.name}}</a>

    <op-icon icon-classes="dropdown-indicator icon-small icon-pulldown"
             icon-title="{{ text.openMenu }}"
             *ngIf="displayDropdownIcon&&headerColumn.flag"></op-icon>
  </span>

</div>
